// This function stores the UTM parameters in session storage
function storeUtmParams() {
    if (typeof window !== 'undefined') {
        // Get the parameters from the URL
        const params = new URLSearchParams(window.location.search);
        // Create an object to store the UTM parameters
        const utmParams = {};
        // Iterate over the parameters and store the UTM ones
        for (const [key, value] of params) {
            if (key.includes('utm_')) {
                utmParams[key] = value;
            }
        }
        // If UTM params found store them in session storage
        if (Object.keys(utmParams).length > 0) {
            //Log params to console
            console.log("UTM Params Found: ", utmParams);
            // Store the UTM parameters in session storage
            sessionStorage.setItem('utmParams', JSON.stringify(utmParams));
        }
    }
    
    return
};

export default storeUtmParams;